.events-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  @include breakpoint(medium) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include breakpoint(large) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  padding: 2rem 0;

  /*Card*/

  .card {
    transition: ease 0.3s;
    box-shadow: ease 0.3s;
    height: 100%;
    background-color: #ffffff;
    padding: 0px;
    padding-bottom: 1rem;
    text-align: center;
    border: 1px solid #e6e6e6;
    background-color: #ffffff;

    img {
      height: 10rem;
      object-fit: cover;
      margin-bottom: 1rem;
    }
    p {
      margin: 0;
      padding: 0 0.5rem;
      font-weight: 700;
      &.day-of-week {
        font-weight: 400;
      }
    }
    h3 {
      font-size: 1rem;
      font-weight: 700;
      margin-top: 1rem;
      background: var(--linkColor, #1a5479) !important;
      padding: 0.85rem 1rem;
      color: #fff !important;
    }
  }

  a:link {
    text-decoration-line: none;
  }

  a > .card:hover,
  .card:hover {
    transform: translatey(-5px);
    color: black;
    box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.2);
    border: 1px solid #ebedef;
  }
  a > .card:hover {
    h3 {
      text-decoration: underline;
    }
  }
}
