.hero {
  position: relative;
  overflow: hidden;
  // max-height: 500px;

  .heroWrapper {
    width: 100%;
  }
}
div.aos__bar {
  height: 2rem;
  position: relative;
  z-index: 9;
}
.aos__up {
  margin-top: -2rem;
}
.aos__admc {
  background: rgb(180, 56, 61);
  background: linear-gradient(
    90deg,
    rgba(180, 56, 61, 1) 35%,
    rgba(255, 255, 255, 0) 100%
  );
}
.aos__edu {
  background: rgb(105, 135, 185);
  background: linear-gradient(
    90deg,
    rgba(105, 135, 185, 1) 35%,
    rgba(255, 255, 255, 0) 100%
  );
}
.aos__sem {
  background: rgb(246, 141, 41);
  background: linear-gradient(
    90deg,
    rgba(246, 141, 41, 1) 35%,
    rgba(255, 255, 255, 0) 100%
  );
}
.aos__business {
  background: rgb(97, 75, 60);
  background: linear-gradient(
    90deg,
    rgba(97, 75, 60, 1) 35%,
    rgba(255, 255, 255, 0) 100%
  );
}
.aos__csit {
  background: rgb(239, 74, 37);
  background: linear-gradient(
    90deg,
    rgba(239, 74, 37, 1) 35%,
    rgba(255, 255, 255, 0) 100%
  );
}
.aos__cht {
  background: rgb(98, 169, 69);
  background: linear-gradient(
    90deg,
    rgba(98, 169, 69, 1) 35%,
    rgba(255, 255, 255, 0) 100%
  );
}
.aos__dmcat {
  background: rgb(24, 73, 144);
  background: linear-gradient(
    90deg,
    rgba(24, 73, 144, 1) 35%,
    rgba(255, 255, 255, 0) 100%
  );
}
.aos__hs {
  background: rgb(58, 176, 169);
  background: linear-gradient(
    90deg,
    rgba(58, 176, 169, 1) 35%,
    rgba(255, 255, 255, 0) 100%
  );
}
.aos__la {
  background: rgb(98, 36, 102);
  background: linear-gradient(
    90deg,
    rgba(98, 36, 102, 1) 35%,
    rgba(255, 255, 255, 0) 100%
  );
}
.aos__pss {
  background: rgb(250, 206, 10);
  background: linear-gradient(
    90deg,
    rgba(250, 206, 10, 1) 35%,
    rgba(255, 255, 255, 0) 100%
  );
}
.aos__content {
  margin: auto;
  height: 2rem;
  display: flex;
  max-width: 890px;
  justify-content: flex-start;
  align-items: center;
}
.aos__text {
  color: white;
  font-size: 1rem;
  padding-left: 0.5rem;
  @include breakpoint(medium) {
    font-size: 1.5rem;
    padding-left: 0rem;
  }
}
.aos__logo {
  margin-left: auto;
  height: 4rem;
  width: 4rem;
  border: none;
  box-shadow: none;
  padding-right: 0.5rem;
  @include breakpoint(medium) {
    border: 5px solid white;
    border-radius: 2rem;
    padding-right: 0rem;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12),
      0 3px 1px -1px rgba(0, 0, 0, 0.2);
  }
}
.hero__image {
  img {
    min-height: 30vw;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 40vw;
    object-fit: cover;
  }

  .stripe {
    display: flex;
    width: 100%;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.75) 0%,
      rgba(0, 0, 0, 0.1) 100%
    );
  }

  .heroTitle {
    position: absolute;
    display: flex;
    bottom: 0;
    width: 100%;
    min-height: 30vw;
    align-items: flex-end;
  }

  h1 {
    margin: 1.5rem 0;
    color: white;
    color: white;
    font-weight: 900;
    text-transform: uppercase;
    width: 100%;
    text-shadow: rgba(0, 0, 0, 0.5) 3px 3px 0px;
    @include breakpoint(medium) {
      font-size: 2.5rem;
    }
  }
}

.hero__html {
  padding: 1.5rem 0;

  @include breakpoint(medium) {
    padding: 2.5rem 0;
  }

  h1 {
    margin: 0 0 1rem;
    // color: white;
    font-weight: 900;
    text-transform: uppercase;
    width: 100%;
    text-shadow: rgba(0, 0, 0, 0.2) 2px 2px 0px;
    @include breakpoint(medium) {
      font-size: 2.5rem;
      line-height: 2.5rem;
    }
  }
}
