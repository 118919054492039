.page-template-page-sidebar-left {
  .page-content .col-alignment {
    @include breakpoint(medium) {
      @include xy-grid;
      flex-direction: row-reverse;
    }

    aside {
      margin: 2rem 0;
      background: rgba(0, 0, 0, 0.05);
      padding: 1rem;

      @include breakpoint(medium) {
        @include xy-cell(4);
      }
    }

    .article-content {
      @include breakpoint(medium) {
        @include xy-cell(auto);
        @include xy-gutters($gutter-position: left);
      }
    }
  }
}
