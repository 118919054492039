.covid {
  // display: flex;
  background-color: rgb(255, 249, 196);
  align-items: stretch;
  justify-content: left;
  align-items: stretch;
  display: none;
}
#covid-bell {
  width: 3rem;

  background-color: #ffeb3b;
  color: rgb(77, 25, 121);
  padding: 0rem 1rem;
  display: flex;
  align-items: center;
}
#covid-text {
  padding: 1rem;
  a {
    color: rgb(77, 25, 121);
    text-decoration: underline;
  }
  p {
    margin: 0 !important;
  }
}
#covid-bell svg {
  width: 1rem;
}
#covid-button {
  display: flex;
  align-items: flex-start;
  margin-left: auto;
  padding: 1rem 1rem;
  button {
    font-weight: 800;
    color: rgb(77, 25, 121);
    &:hover {
      color: #ccc;
    }
  }
}
