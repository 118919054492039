/*!
Theme Name: Basic IW Theme
Theme URI: http://instruction.austincc.edu
Author: ACC Instructional Web
Author URI: instruction.austincc.edu
Description: Description
Version: 1.3.8
License: GNU General Public License v2 or later
License URI: LICENSE
Text Domain: instructional-web
*/

@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");

@import "foundation";

// @import "@material/card/mdc-card";

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: var(--headerColor, #1a5276);
  width: 100%;
  overflow-x: hidden;
}

main a {
  color: #1a5276;
  color: var(--linkColor, #1a5479) !important;
  text-decoration: underline;
  position: relative;
  display: inline-block;
  z-index: 2;
}

main p a:not(.button) {
  // border-bottom: 2px solid var(--linkColor, #1a5479) !important;
  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0;
    transition: width 0.15s cubic-bezier(0.65, 0.05, 0.36, 1);
  }
}

main p a:hover:not(.button) {
  &:before {
    content: "";
    display: block;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: var(--linkColor, #1a5479) !important;
    opacity: 0.15;
    width: 100%;
  }
}

main a.button {
  background: #1a5479;
  background: var(--linkColor, #1a5479) !important;
  color: white !important;
  text-decoration: none;
  font-size: 1rem;
}

main a.button:hover,
main a.button:focus {
  text-decoration: underline;
}

// main a:hover,
// main a:focus {
//   &::before {
//     content: '';
//     display: block;
//     position: absolute;
//     left: 0;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     background-color: var(--linkColor, #1a5479) !important;
//     opacity: 0.1;
//     z-index: 1;
//   }
// }
p a,
.article-content a {
  font-weight: bold;
}

.article-content li a {
  font-weight: normal;
}

h1.sectionHeading,
h1.articleHeading {
  text-transform: uppercase;
  font-weight: 700;
  color: #1a5276;
  color: var(--linkColor, #1a5479) !important;
  margin-bottom: 1.5rem;

  &:after {
    content: "";
    display: block;
    border-bottom: 2px solid #1a5479;
    border-color: var(--linkColor, #1a5479) !important;
    opacity: 0.3;
  }
}

.wrapper {
  @include xy-grid-container();
  margin: 0 auto;
  box-sizing: border-box;
}

.screen-reader-text {
  @include element-invisible;
}

// .content-wrapper {
//   padding: 1.5rem map-safe-get($grid-column-gutter, small);
//   @include breakpoint(medium) {
//     padding: 1.5rem map-safe-get($grid-column-gutter, medium);
//   }
// }

// force images to stay inside their responsive containers
.size-auto,
.size-full,
.size-large,
.size-medium,
.size-thumbnail,
img {
  max-width: 100%;
  height: auto;
}

/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Menus
# Alignments
# Clearings
# Widgets
# Content
	## Posts and pages
	## Comments
# Infinite scroll
# Media
	## Captions
	## Galleries
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Menus
--------------------------------------------------------------*/
@import "site-nav";

/*--------------------------------------------------------------
# Alignments
--------------------------------------------------------------*/
.alignleft {
  display: inline;
  float: left;
  margin-right: 1.5em;
}

.alignright {
  display: inline;
  float: right;
  margin-left: 1.5em;
}

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/*--------------------------------------------------------------
# Widgets
--------------------------------------------------------------*/
.widget {
  margin: 0 0 1.5em;
}

/* Make sure select elements fit in widgets. */
.widget select {
  max-width: 100%;
}

/*--------------------------------------------------------------
# Content
--------------------------------------------------------------*/
/*--------------------------------------------------------------
## Posts and pages
--------------------------------------------------------------*/
.sticky {
  display: block;
}

.hentry {
  margin: 0 0 1.5em;
}

.updated:not(.published) {
  display: none;
}

.entry-content,
.entry-summary {
  margin: 1.5em 0 0;
}

.page-links {
  clear: both;
  margin: 0 0 1.5em;
}

/*--------------------------------------------------------------
## Comments
--------------------------------------------------------------*/
.comment-content a {
  word-wrap: break-word;
}

.bypostauthor {
  display: block;
}

/*--------------------------------------------------------------
# Infinite scroll
--------------------------------------------------------------*/
/* Globally hidden elements when Infinite Scroll is supported and in use. */
.infinite-scroll .posts-navigation,
/* Older / Newer Posts Navigation (always hidden) */
.infinite-scroll.neverending .site-footer {
  /* Theme Footer (when set to scrolling) */
  display: none;
}

/* When Infinite Scroll has reached its end we need to re-display elements that were hidden (via .neverending) before. */
.infinity-end.neverending .site-footer {
  display: block;
}

/*--------------------------------------------------------------
# Media
--------------------------------------------------------------*/
.page-content .wp-smiley,
.entry-content .wp-smiley,
.comment-content .wp-smiley {
  border: none;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
}

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
  max-width: 100%;
}

/* Make sure logo link wraps around logo image. */
.custom-logo-link {
  display: inline-block;
}

/*--------------------------------------------------------------
## Captions
--------------------------------------------------------------*/
.wp-caption {
  margin-bottom: 1.5em;
  max-width: 100%;
}

.wp-caption img[class*="wp-image-"] {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption .wp-caption-text {
  margin: 0.8075em 0;
}

.wp-caption-text {
  text-align: center;
}

/*--------------------------------------------------------------
## Galleries
--------------------------------------------------------------*/
.gallery {
  margin-bottom: 1.5em;
}

.gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.33333%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66667%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28571%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11111%;
}

.gallery-caption {
  display: block;
}

@import "site-header";
@import "site-footer";
@import "page-templates/default";
@import "page-templates/sidebar-left";
@import "page-templates/sidebar-right";
@import "page-templates/sidebar-left-right";
@import "page-templates/music-events";
@import "page-templates/home";
@import "acf-layouts";
@import "hero";
@import "accordion";
@import "get-started";
@import "carousel";
@import "tease";
@import "article";
@import "covid";
@import "video-header";
@import "print";
