.site-footer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: #fff; // IE
  color: var(--headerTextColor, #fff);
  background: #1a5276; // IE
  background: var(--headerColor, #1a5276);

  .wrapper {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  h2 {
    border-bottom: 1px dashed var(--headerTextColor, #fff);
    margin-bottom: 1.5rem;
  }

  .footerTLEDLogo {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    text-align: center; 
    background: rgba(0,0,0,0.3); 
    padding: 1rem 0;
  }

  .accLogo {
    display: block;
    max-width: 150px;
    margin-bottom: 1.5rem;
  }

  .aosList {
    ul {
      columns: 15rem auto;
      column-gap: 1.5rem;
    }
    li {
      margin-bottom: 1rem;
    }
    div {
      font-weight: 700;
    }
  }

  a {
    color: var(--headerTextColor, #fff);
    word-wrap: break-word;
  }

  a:hover,
  a:focus {
    text-decoration: underline;
  }
}
