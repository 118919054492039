.page-template-page-sidebar-left-right {
  .page-content .col-alignment {
    padding-bottom: 2rem;

    @include breakpoint(medium) {
      @include xy-grid;
      flex-direction: row;
    }

    aside {
      margin: 2rem 0 0;
      background: rgba(0, 0, 0, 0.05);
      padding: 1rem;
      box-sizing: border-box;

      @include breakpoint(medium) {
        @include xy-cell(3);
      }

      &:first-of-type {
        @include breakpoint(medium) {
          order: 1;
        }
      }

      &:last-of-type {
        @include breakpoint(medium) {
          order: 3;
        }
      }
    }

    .article-content {
      @include breakpoint(medium) {
        order: 2;
        @include xy-cell(auto);
        @include xy-gutters();
      }
    }
  }
}
