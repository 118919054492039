.acfSection.text_light {
  color: white;

  h1 {
    color: white !important;
    &:after {
      border-bottom-color: white !important;
    }
  }
}

.acfSection__carousel {
  padding: 2rem 0 4rem;
}

.layout-one-column {
  margin-bottom: 1.5rem;
}

.layout-two-column {
  margin-bottom: 1.5rem;
  @include breakpoint(medium) {
    @include xy-grid;
  }
  & > .col {
    padding: 1rem 0;
    // @include iw-column(6);
    @include breakpoint(medium) {
      @include xy-cell(auto);
    }
  }
  & > .col:first-child {
    @include breakpoint(medium) {
      @include xy-gutters($gutter-position: right);
    }
  }
  & > .col:last-child {
    @include breakpoint(medium) {
      @include xy-gutters($gutter-position: left);
    }
  }
}

.layout-three-column {
  margin-bottom: 1.5rem;
  @include breakpoint(medium) {
    @include xy-grid;
  }

  & > .col {
    // @include iw-column(4);
    padding: 1rem 0;
    @include breakpoint(medium) {
      @include xy-cell(auto);
    }
  }
  & > .col:first-child {
    @include breakpoint(medium) {
      @include xy-gutters($gutter-position: right);
    }
  }
  & > .col:nth-child(2) {
    @include breakpoint(medium) {
      @include xy-gutters($gutter-position: right left);
    }
  }
  & > .col:last-child {
    @include breakpoint(medium) {
      @include xy-gutters($gutter-position: left);
    }
  }
}

.layout-four-column {
  margin-bottom: 1.5rem;
  @include breakpoint(medium) {
    @include xy-grid;
  }

  & > .col {
    // @include iw-column(4);
    padding: 1rem 0;
    @include breakpoint(medium) {
      @include xy-cell(auto);
    }
  }
  & > .col:first-child {
    @include breakpoint(medium) {
      @include xy-gutters($gutters: 18px, $gutter-position: right);
    }
  }
  & > .col:nth-child(2) {
    @include breakpoint(medium) {
      @include xy-gutters($gutters: 18px, $gutter-position: right left);
    }
  }
  & > .col:nth-child(3) {
    @include breakpoint(medium) {
      @include xy-gutters($gutters: 18px, $gutter-position: right left);
    }
  }
  & > .col:last-child {
    @include breakpoint(medium) {
      @include xy-gutters($gutters: 18px, $gutter-position: left);
    }
  }
}

.layout-four-column.cards {
  .col {
    border-radius: 2px;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
      0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
    padding-top: 0;
    margin-bottom: 1rem;

    p {
      text-align: center;

      img {
        border-radius: 2px 2px 0 0;
      }
    }
    p:nth-child(2) {
      padding: 0 20px;
      border-radius: 0 0 2px 2px;
      text-align: left;
    }
  }
}
