/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */

// Dependencies
@import "foundation-sites/scss/vendor/normalize.scss";
@import "foundation-sites/_vendor/sassy-lists/stylesheets/helpers/_missing-dependencies.scss";
@import "foundation-sites/_vendor/sassy-lists/stylesheets/helpers/_true.scss";
@import "foundation-sites/_vendor/sassy-lists/stylesheets/functions/_contain.scss";
@import "foundation-sites/_vendor/sassy-lists/stylesheets/functions/_purge.scss";
@import "foundation-sites/_vendor/sassy-lists/stylesheets/functions/_remove.scss";
@import "foundation-sites/_vendor/sassy-lists/stylesheets/functions/_replace.scss";
@import "foundation-sites/_vendor/sassy-lists/stylesheets/functions/_to-list.scss";

// Settings
@import "settings";

// Sass utilities
// @import 'util/util';
@import "foundation-sites/scss/util/math";
@import "foundation-sites/scss/util/unit";

@import "foundation-sites/scss/util/value";
@import "foundation-sites/scss/util/direction";
@import "foundation-sites/scss/util/color";
@import "foundation-sites/scss/util/selector";
@import "foundation-sites/scss/util/flex";
@import "foundation-sites/scss/util/breakpoint";
@import "foundation-sites/scss/util/mixins";
@import "foundation-sites/scss/util/typography";

// Global variables and styles
@import "foundation-sites/scss/global";

// Components
@import "foundation-sites/scss/grid/grid";
@import "foundation-sites/scss/xy-grid/xy-grid";
@import "foundation-sites/scss/typography/typography";
@import "foundation-sites/scss/forms/forms";
@import "foundation-sites/scss/components/visibility";
@import "foundation-sites/scss/components/float";
@import "foundation-sites/scss/components/button";
@import "foundation-sites/scss/components/button-group";
@import "foundation-sites/scss/components/accordion-menu";
@import "foundation-sites/scss/components/accordion";
@import "foundation-sites/scss/components/badge";
@import "foundation-sites/scss/components/breadcrumbs";
@import "foundation-sites/scss/components/callout";
@import "foundation-sites/scss/components/card";
@import "foundation-sites/scss/components/close-button";
@import "foundation-sites/scss/components/drilldown";
@import "foundation-sites/scss/components/dropdown-menu";
@import "foundation-sites/scss/components/dropdown";
@import "foundation-sites/scss/components/flex";
@import "foundation-sites/scss/components/responsive-embed";
@import "foundation-sites/scss/components/label";
@import "foundation-sites/scss/components/media-object";
@import "foundation-sites/scss/components/menu";
@import "foundation-sites/scss/components/menu-icon";
@import "foundation-sites/scss/components/off-canvas";
@import "foundation-sites/scss/components/orbit";
@import "foundation-sites/scss/components/pagination";
@import "foundation-sites/scss/components/progress-bar";
@import "foundation-sites/scss/components/reveal";
@import "foundation-sites/scss/components/slider";
@import "foundation-sites/scss/components/sticky";
@import "foundation-sites/scss/components/switch";
@import "foundation-sites/scss/components/table";
@import "foundation-sites/scss/components/tabs";
@import "foundation-sites/scss/components/title-bar";
@import "foundation-sites/scss/components/top-bar";
@import "foundation-sites/scss/components/thumbnail";
@import "foundation-sites/scss/components/tooltip";
// @import 'foundation-sites/scss/prototype/prototype';

$global-flexbox: true !global;
@include foundation-global-styles;
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-forms;
@include foundation-button;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-flex-classes;
