.site-header {
  // background: #1a5276; // IE
  background-image: none;
  
  // background: var(--headerColor, #1a5276) var(--customHeaderImage) center center;
  background: var(--customHeaderImage, none) center center no-repeat;
  background-size: cover;

  color: #fff; // IE
  color: var(--headerTextColor, #fff);
  
  a {
    color: #fff; // IE
    color: var(--headerTextColor, #fff) !important;
  }

  .acc-header {
    padding: 1rem 0 0;
    background: rgba(0,0,0,0.4);
    background: var(--headerColor, #1a5276);
    .wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;

      @include breakpoint(medium) {
        flex-wrap: nowrap;
      }
    }

    .siteIdentity {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      // flex-shrink: 0;
      margin-bottom: 1rem;

      .siteIdentity__primary {
        display: flex;
        flex-wrap: wrap;

        .siteLogo {
          display: inline-block;
          width: 100px;
          margin-right: 1rem;
        }
        .siteTitle {
          margin-bottom: 0;
          margin-top: 5px;
        }
      }

      .siteDescription {
        margin-bottom: 0;
        font-size: 1rem;
        color: rgba(255, 255, 255, 0.7);
      }
    }

    .siteControls {
      margin-bottom: 1rem;
    }

    .widgetWrapper {
      width: 100%;
    }

    .headerWidget {
      svg {
        fill: white;
        width: 24px;
        height: 24px;
      }
    }

    .jetpack-social-widget-list {
      list-style: none;
      display: flex;
      justify-content: flex-end;
      padding-top: 0.5rem;
      margin: 0;
      li:not(:last-of-type) {
        margin-right: 0.5rem;
      }
    }

    .searchForm {
      display: flex;
      align-items: flex-end;
      justify-self: flex-end;
      margin-left: auto;
      min-width: 250px;
      flex-shrink: 0;
      flex-grow: 1;
      max-width: 650px;
      background-color: rgba(255,255,255,0.2);
      // flex-direction: row-reverse;

      label {
        // show for screen readers
        @include element-invisible;
      }
      
      input {
        display: inline-block;
        background-color: rgba(255,255,255,0);
        border: none;
        margin: 0;
        transition: background-color 0.3s;
        // transform: scaleX(0);
        // transform-origin: center right;
        box-shadow: none;
        width: 100%;
        height: 2.5rem;
        font-size: 1rem;
        padding: 0.5rem;
        font-weight: normal;
        line-height: 1.5;
        color: white;

        ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: white;
        }
        ::-moz-placeholder { /* Firefox 19+ */
          color: white;
        }
        :-ms-input-placeholder { /* IE 10+ */
          color: white;
        }
        :-moz-placeholder { /* Firefox 18- */
          color: white;
        }

        &:hover,
        &:focus {
          background: rgba(255,255,255,0.7);
          color: black;
        }
      }
  
      button {
        display: flex;
        align-items: center;
        height: 2.4375rem;
        background: none;
        padding: 10px;
        box-sizing: border-box;
        border: none;
        span {
          @include element-invisible;
        }
        .iw-icon-search {
          display: block;
          min-height: 20px;
          height: 20px;
          width: 20px;
          fill: white;
        }

        &:focus {
          background: white;

          .iw-icon-search {
            fill: $blue;
          }
        }
      }
    }
  
    .searchForm.focus {
      input {
        transform: scaleX(1);
        background-color: white;
        color: $body-text;
      }
    }
    



    // .searchForm {
    //   display: flex;
    //   align-items: flex-end;
    //   flex: 0 1 265px;
    // }
    
    // .searchForm input {
    //   display: inline-block;
    //   box-sizing: border-box;
    //   background: rgba(255, 255, 255, 0.1);
    //   width: 100%;
    //   height: 2.5rem;
    //   font-size: 1rem;
    //   padding: 0.5rem;
    //   font-weight: normal;
    //   line-height: 1.5;
    //   border: none;
    //   color: white;
    // }
    
    // .searchForm input:focus {
    //   outline: none;
    //   /* border: 1px solid #8a8a8a; */
    //   border: none;
    //   background-color: white;
    //   /* box-shadow: 0 0 5px #cacaca; */
    //   box-shadow: none;
    //   color: black;
    // }
    
    // .searchForm button {
    //   background: none;
    // }
    
    // .searchForm button {
    //   display: flex;
    //   align-items: center;
    //   height: 2.5rem;
    //   background: white;
    //   padding: 10px;
    //   box-sizing: border-box;
    //   border: none;
    //   transition: background 0.3s ease;
    // }
    
    // .searchForm button span {
    //   position: absolute !important;
    //   width: 1px;
    //   height: 1px;
    //   padding: 0;
    //   overflow: hidden;
    //   clip: rect(0, 0, 0, 0);
    //   white-space: nowrap;
    //   -webkit-clip-path: inset(50%);
    //   clip-path: inset(50%);
    //   border: 0;
    // }
    
    // .searchForm button svg {
    //   fill: white;
    //   display: block;
    //   min-height: 20px;
    //   height: 20px;
    //   width: 20px;
    // }
    
    // .searchForm:focus button,
    // .searchForm.focus button,
    // .searchForm.expanded button {
    //   background: white;
    // }
    
    // .searchForm:focus button svg,
    // .searchForm.focus button svg,
    // .searchForm.expanded button svg {
    //   fill: #4d4d4d;
    // }
  }

  .custom-logo-link,
  .iw-tled-logo {
    display: inline-block;
    width: 100px;
    height: auto;
    margin-right: 1rem;
  }

  // .site-title {
  //   .wrapper {
  //     display: flex;
  //     padding-top: 1rem;
  //     padding-bottom: 1rem;
  //     align-items: flex-end;
  //   }
  //   h1 {
  //     margin-bottom: 0;
  //   }
  //   a {
  //     color: white;
  //   }
  // }

  .site-description {
    @extend .h5;
    color: $light-gray;
  }
}