.blog,
.archive,
.category {
  .page-content .col-alignment {
    @include breakpoint(medium) {
      @include xy-grid;
      flex-direction: row;
    }

    .layout-sidebar {
      margin: 2rem 0;
      background: rgba(0, 0, 0, 0.05);
      padding: 1rem;

      @include breakpoint(medium) {
        @include xy-cell(4);
      }
    }

    .blogIndex {
      margin-left: 0;
      @include breakpoint(medium) {
        @include xy-cell(auto);
        @include xy-gutters($gutter-position: right);
      }
    }
  }
}
// .blogIndex {
//   @include breakpoint(medium) {
//     @include xy-grid;
//     // @include xy-cell(9);
//     // @include xy-gutters($gutter-position:right);
//   }

//   article.tease {
//     // @include breakpoint(medium) {
//       // @include xy-cell();
//       // width: calc(50% - 1.25rem);

//       // &:nth-child(odd) {
//       //   @include xy-gutters($gutter-position:right);
//       // }

//       // &:nth-child(even) {
//       //   @include xy-gutters($gutter-position:left);
//       // }
//     // }

//     .tease__thumbnail {
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       margin-bottom: 1rem;
//       max-height: 50%;
//       overflow: hidden;
//       img {
//         width: 100%;
//         height: 100%;
//       }
//     }
//     .tease__content {}
//     .tease__content.full {}
//   }
// }
