.get-started__container {
  background: #4d1979;
  height: 200px;
  justify-content: center;
  align-content: center;
  padding-top: 1.8em;
  padding-bottom: 1.8em;
}
.get-started__title {
  font-size: 42px;
  color: white;
  text-transform: uppercase;
  font-weight: 500;
  display: block;
  text-align: center;
  text-shadow: 2px 2px 0 #3d1e4a;
}
.get-started__buttons {
  text-align: center;
  display: block;
  text-transform: uppercase;
  a.button {
    border-color: rgb(189, 189, 189);
    border-width: 1px;
    background: #4d1979;
    font-weight: 200;
    padding: 1rem 2.2rem;
    &:hover {
      border-color: white;
      background-color: #260859;
    }
    &:focus {
      color: white;
    }
  }
  .button {
    margin-right: 0.1em;
    font-size: 1em;
  }
}
