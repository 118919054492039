@media (min-width: 600px) {
  article.tease-post .dt-wrapper {
    display: flex !important;
  }
}

h1.category-title {
  font-size: 36px;
  font-weight: 700;
  padding-top: 36px;
}

article.tease-post {
  .dt-wrapper {
    display: block;

    .thumbnail {
      border: none;
      box-shadow: none;

      img {
        min-width: 285px;
      }
    }

    .dt-content {
      padding-left: 1rem;

      p a.read-more {
        display: block;
        margin-top: 1.4rem;
        font-size: 14px;
        text-transform: uppercase;
      }

      .dt-post-meta {
        p {
          display: inline-block;
          border-right: 1px solid #000;
          margin-right: 7px;
          padding: 0 7px 0 0;
          line-height: 1rem;
          font-size: 14px;
          text-transform: uppercase;
          color: #000;
        }
        p:first-child {
          font-weight: 700;
        }
        p:last-child {
          border-right: none;
          padding-right: 0;
        }
      }
    }
  }
  margin-top: 2rem;

  .tease__content {
    padding: 1rem;
  }

  .tease__thumbnail {
    margin-bottom: 1rem;
    height: 0;
    padding-bottom: 41.425%; // silver ratio
    position: relative;

    img {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      @include object-fit(cover, center);
    }
  }
}

// #### Category Page ####

.tool-pagination {
  margin: 0 auto;
  padding: 36px 0;
  font-size: 18px;
  font-weight: 700;
  width: fit-content;
  ul.pages {
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0 6px;
    li {
      padding: 0 6px;
      display: inline-block;
    }
  }
}

#digitex-post {
  h1 {
    // color: #a02521;
    font-size: 66px;
    font-weight: normal;
    line-height: 1.1em;
    text-transform: uppercase;
    text-align: center;
  }

  .category {
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 1rem;
  }

  .dt-post-meta {
    display: flex;
    margin: 40px auto;
    padding: 15px 15px 15px 15px;
    border-style: dotted;
    border-width: 1px 0px 1px 0px;
    border-color: #aaaaaa;
    width: 70%;

    p {
      flex-grow: 1;
      padding: 0 1rem;
      margin-bottom: 0;

      &:first-child {
        border-right: 1px solid #aaaaaa;
        text-align: right;
      }
    }
  }

  #author-box {
    margin: 30px auto 50px;
    padding: 30px 0;
    border-style: dotted;
    border-width: 1px 0px 1px 0px;
    border-color: #aaaaaa;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;

    img {
      width: 100px;
      border-radius: 500px;
      -o-object-fit: cover;
      object-fit: cover;
      margin-right: 25px;
    }
  }

  .article-body a {
    display: inline;
  }
  .sharedaddy a {
    display: inline-block;
  }
}
