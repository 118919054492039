@media print {
  #covid-notification,
  header,
  nav,
  footer,
  .get-started__container {
    display: none !important;
  }

  @page {
    margin: 2cm;
  }
}
