.skip-link {
  position: absolute;
  left: -999rem;
  &:focus {
    left: 10px;
    top: 10px;
    background: white;
    padding: 1rem;
    z-index: 999;
  }
}

.siteControls {
  display: flex;
  width: 100%;
  @include breakpoint(medium) {
    width: auto;
  }
}

.site-header,
.site-footer,
.page-content {
  position: relative;
  left: 0;
  transition: left 0.3s;
  will-change: left;
}

body.menuOpen {
  .site-header,
  .page-content,
  .site-footer {
    position: relative;
    left: 75vw;
    z-index: 999;
  }

  .site-nav {
    left: 0;
    z-index: 998;
  }
}

.siteControls__menuToggle {
  display: flex;
  max-width: 28px;
  // padding: 4px 6px;
  // background: rgba(255, 255, 255, 0.1);
  margin: 0.5rem 1rem auto 0;

  iw-icon-bars {
    display: block;
    width: 28px;
    height: 28px;
    fill: white;
  }
  @include breakpoint(medium) {
    display: none;
  }
}

.mobileNavCloseBtn {
  background: white;
  border: none;
  color: $body-font-color;
  font-size: 2rem;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0.6rem;
  right: 1rem;
  line-height: 0;

  @include breakpoint(medium) {
    display: none;
  }
}

.site-nav {
  position: absolute;
  left: -75vw;
  will-change: left;
  transition: left 0.3s;
  top: 0;
  width: 75vw;
  background: #1a5276; // IE
  background: var(--menuColor, #1a5276);

  @include breakpoint(medium) {
    width: 100%;
    left: auto;
    position: relative;
    height: auto;
    iw-close {
      display: none;
    }
  }

  .wrapper {
    // margin: 0;
  }
}

.iw-dropdown-menu {
  list-style: none;
  margin: 0;
  margin-top: 3rem;

  // @include foundation-menu;
  // @include foundation-dropdown-menu;

  ul,
  ul ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  @include breakpoint(medium) {
    margin-top: 0;
  }
}

.iw-dropdown-menu .dropdown.menu {
  @include menu-direction(vertical);
  margin: 0 -0.9375rem;

  @include breakpoint(medium) {
    @include menu-direction(horizontal);
    margin: 0;
  }

  &[hidden] {
    display: none;
  }

  a {
    color: #fff; // IE
    color: var(--headerTextColor, #fff);
    line-height: 1.5rem;
    display: inline-flex;
    align-items: center;
    width: 100%;

    svg {
      width: 1.5rem;
      height: 1.5rem;
      fill: white;
      fill: var(--headerTextColor, #fff);
    }
  }

  & > li > a {
    background-color: transparent;
    padding: 1rem 1rem;
  }

  // li.current-menu-item > a {
  //   background: #1a5276; // IE
  //   background: var(--menuHiliteB, #1a5276);

  //   color: #fff; // IE
  //   color: var(--headerTextColor, #fff);
  // }

  li.is-submenu-item {
    &.is-active > a,
    &.current-menu-item > a,
    & > a:hover,
    & > a:focus {
      background: #1a5276; // IE
      background: var(--menuHiliteB, #1a5276);

      color: #fff; // IE
      color: var(--headerTextColor, #fff);
    }
  }

  li:not(.is-submenu-item) {
    &.is-active > a,
    &.current-menu-item > a,
    & > a:hover,
    & > a:focus {
      background: #6a8da3; // IE
      background: var(--menuHiliteA, #6a8da3);

      color: #fff; // IE
      color: var(--headerTextColor, #fff);
    }
  }

  li.is-dropdown-submenu-parent {
    & > a {
      color: var(--headerTextColor, #fff);
      svg {
        transform: rotate(-90deg);
      }
    }
    &.is-active > a {
      svg {
        transform: rotate(0);
      }
    }
  }

  .is-dropdown-submenu {
    z-index: 9999;
    background: #6a8da3; // IE
    background: var(--menuHiliteA, #6a8da3);

    color: #fff; // IE
    color: var(--headerTextColor, #fff);
    border: none;
    a:hover,
    a:focus {
      background: #1a5276; // IE
      background: var(--menuHiliteB, #1a5276);
    }
    position: relative;
    @include breakpoint(medium) {
      position: absolute;
    }
  }
}
#siteNavigation {
  display: none;
}
.stickySiteNav {
  position: absolute;
  z-index: 1000;

  @include breakpoint(medium) {
    position: sticky;
  }
}
