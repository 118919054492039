.accordion {
  .accordion-title {
    font-size: 1rem;
    border: none;
    border-bottom: 1px solid #e6e6e6;
    text-decoration: none;
    padding-right: 3rem;

    &:before {
      font-size: 1.3rem;
      font-weight: 700;
      margin-top: -0.55rem;
    }
  }
  .accordion-content {
    border: none;
  }
}
