.hero__video {
  padding: 0 0 1.5rem 0;
}
.hero__video a {
  color: white !important;
}
@media print, screen and (min-width: 40em) {
  .hero__video {
    padding: 0 0 2.5rem 0;
  }
}
video {
  width: 100%;
}
.hero__video--wrapper {
  width: 100vw;
  margin-left: -50vw;
  left: 50%;
  position: relative;
  max-height: 50vh;
}
.video__blurb {
  padding-top: 1rem;
  position: absolute;
  z-index: 999;
  color: white;
  min-width: 100%;
  bottom: -3rem;
  text-transform: uppercase;
  font-weight: 700;
  text-shadow: rgba(0, 0, 0, 0.2) 2px 2px 0px;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.75) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
  padding-bottom: 0.5rem;
}
.video__blurb *,
.video__blurb h1 {
  margin: auto;
  max-width: 960px;
  margin-bottom: 0.8rem;
}
