.article {

}

.article-date {
  font-size: 0.9rem;
  font-style: italic;
}

.article__featuredImage {
  margin-bottom: 1rem;
  height: 0;
  padding-bottom: 41.425%; // silver ratio
  position: relative;

  img {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    @include object-fit(cover, center);
  }
}